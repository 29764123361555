import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'shared/button';
import { Flex, Grid } from 'shared/grid';
import { openSignUpModal } from 'shared/redux/globals/actions';
import { unType } from 'shared/utils/cms';
import { useAppDispatch } from '../../store';
import {
  GridContainer,
  Panel,
  SectionHeading,
  SectionSubtitle,
  SectionTagline,
} from '../landing-shared';

const CTA = ({ data, ...rest }) => {
  const {
    title,
    subtitle: st,
    description,
    tagline,
    ctaButtonText,
    sectionId,
    backgroundImage,
  } = React.useMemo(() => unType(data), [data]);
  const dispatch = useAppDispatch();

  const subtitle = st || description;

  return (
    <Panel {...rest} sectionId={sectionId} backgroundImage={backgroundImage}>
      <GridContainer gridGap={4} py={4}>
        <Grid>
          {tagline && <SectionTagline>{tagline}</SectionTagline>}
          <SectionHeading>{title}</SectionHeading>
          {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
        </Grid>
        {ctaButtonText && (
          <Flex justifyContent="center">
            <Button
              size="large"
              color="brand1"
              variant="solid"
              onClick={() => dispatch(openSignUpModal())}
              iconRight={faArrowRight}
            >
              {ctaButtonText || (
                <FormattedMessage
                  id="LandingPage.tryIt"
                  defaultMessage="Sign up"
                />
              )}
            </Button>
          </Flex>
        )}
      </GridContainer>
    </Panel>
  );
};

export default CTA;
