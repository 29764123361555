import React from 'react';
import { Box, Flex, Grid } from 'shared/grid';
import { unType } from 'shared/utils/cms';
import {
  GridContainer,
  Panel,
  Paragraphs,
  SectionHeading,
  SectionSubtitle,
  SectionTagline,
} from '../landing-shared';
import { useHasMounted } from 'shared/client-only';
import useComponentSize from 'shared/hooks/component-size';
import { Heading } from 'shared/typography';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { themeGet } from '@styled-system/theme-get';
import { Theme, ThemeContext } from '@emotion/react';
import Color from 'color';

const AccordionItem = ({ defaultOpen = false, title, children }) => {
  const contentRef = React.useRef(null);
  const { height } = useComponentSize(contentRef);
  const hasMounted = useHasMounted();
  // Ensures we show google everything
  const contentHeight = !hasMounted ? 9999 : height;
  const [isOpen_, setIsOpen] = React.useState(defaultOpen);
  const isOpen = !hasMounted || isOpen_;

  const theme = React.useContext<Theme>(ThemeContext as any);

  return (
    <Box
      borderColor={
        isOpen
          ? 'brand0.5'
          : Color(themeGet(`colors.gray.9`)({ theme })).alpha(0.1).string()
      }
      borderWidth={'4px'}
      borderLeftStyle={['none', 'solid']}
      // borderBottomStyle={['solid', 'none']}
      px={[0, 4]}
      // mx={-3}
    >
      <Flex
        width="100%"
        as="button"
        type="button"
        justifyContent="space-between"
        alignItems="center"
        onClick={(e) => {
          setIsOpen(!isOpen);
        }}
        py={3}
        color={isOpen ? 'brand0.7' : 'gray.9'}
      >
        <Heading
          fontSize={[2, 3]}
          fontWeight={500}
          sx={{
            overflowWrap: 'break-word',
            textAlign: 'left',
            maxWidth: ['calc(100% - 48px)', 'calc(100% - 48px)'],
          }}
        >
          {title}
        </Heading>

        <Box
          // display={['block', 'none']}
          sx={{
            transition: 'transform 200ms ease-in-out',
            transitionDelay: isOpen ? undefined : '200ms',
            transform: isOpen ? 'rotate(90deg)' : 'rotate(-90deg)',
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Box>
      </Flex>

      <Box
        sx={{
          transition: 'max-height 200ms ease-in-out, opacity 200ms ease-in-out',
          maxHeight: isOpen ? contentHeight : 0,
          opacity: isOpen ? 1 : 0,
          overflow: 'hidden',
          // maxWidth: [undefined, 'calc(100% - 32px)'],
          maxWidth: [undefined, '700px'],
        }}
      >
        <Grid pt={0} pb={3} ref={contentRef}>
          {children}
        </Grid>
      </Box>
    </Box>
  );
};

const FAQ = ({ data, ...rest }) => {
  const { title, subtitle, tagline, items, sectionId } = React.useMemo(
    () => unType(data),
    [data]
  );

  // const theme = React.useContext<Theme>(ThemeContext as any);
  // const primary = themeGet(`colors.brand0.9`)({ theme });

  return (
    <Panel {...rest} sectionId={sectionId}>
      <GridContainer pb={4}>
        <Grid>
          {tagline && <SectionTagline>{tagline}</SectionTagline>}
          <SectionHeading>{title}</SectionHeading>
          {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
        </Grid>
        {/* <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridGap={4}> */}
        <Box>
          {items.map(({ question, answer }, idx) => (
            <AccordionItem defaultOpen={false} key={idx} title={question}>
              <Paragraphs text={answer} />
            </AccordionItem>
          ))}
        </Box>
        {/* <Flex justifyContent="center" alignItems="center">
            <Grid
              sx={{
                color: '#fff',
                textShadow: `0 0 7px #fff,
  0 0 10px #fff,
  0 0 21px #fff,
  0 0 42px ${primary},
  0 0 82px ${primary},
  0 0 92px ${primary},
  0 0 102px ${primary},
  0 0 151px ${primary}`,
                fontSize: 60,
              }}
            >
              ?
            </Grid>
          </Flex>
        </Grid> */}
      </GridContainer>
    </Panel>
  );
};

export default FAQ;
