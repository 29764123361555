import invariant from 'tiny-invariant';

export const unType = (obj) => {
  if (obj?.type) {
    if (obj.type === 'array' && Array.isArray(obj.value)) {
      return obj.value.map((v) => unType(v));
    } else {
      return unType(obj.value);
    }
  } else if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
    return Object.fromEntries(
      Object.entries<any>(obj).map(([k, v]) => [k, v.type ? unType(v) : v])
    );
  } else {
    return obj;
  }
};

export const replaceReferences = (obj, entryMap) => {
  if (obj?.type) {
    if (obj.type === 'reference') {
      return entryMap[obj.value];
    } else if (obj.type === 'array' && Array.isArray(obj.value)) {
      return {
        ...obj,
        value: obj.value.map((v) => replaceReferences(v, entryMap)),
      };
    } else {
      return { ...obj, value: replaceReferences(obj.value, entryMap) };
    }
  } else if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
    return Object.fromEntries(
      Object.entries<any>(obj).map(([k, v]) => [
        k,
        v.type ? replaceReferences(v, entryMap) : v,
      ])
    );
  } else {
    return obj;
  }
};

export const getInEntry = (path, entry, language = 'en', warn = false) => {
  try {
    const parts = path.split('.');
    let resolved = entry.value;

    for (const part of parts) {
      const type = resolved[part]?.type;
      resolved = resolved[part]?.value;

      if (resolved === undefined) {
        if (warn) {
          console.warn(`Cannot resolve ${part}`);
        }
      }

      if (type === 'translation') {
        resolved = (resolved[language] || resolved['en']).value;
      }
    }

    return resolved;
  } catch (e) {
    return null;
  }
};

const matchPath = (p1: string, p2: string) => {
  const length = Math.min(p2.length, p1.length);
  return p1.substring(0, length) === p2.substring(0, length);
};

const includePath = (
  path: string,
  includePaths: string[],
  excludePaths: string[]
) => {
  const isIncluded =
    includePaths.length > 0
      ? includePaths.some((p) => matchPath(p, path))
      : true;

  const isExcluded =
    excludePaths.length > 0
      ? excludePaths.some((p) => matchPath(p, path))
      : false;

  return isIncluded && !isExcluded;
};

export const filterEntry = (
  obj: Record<string, any>,
  includePaths = [],
  excludePaths = [],
  path: string = null
): Record<string, any> => {
  if (!obj) {
    return obj;
  }

  if (typeof obj.value !== 'object' && obj.type !== 'translation') {
    // console.log('not object', obj);
    return obj;
  }

  const newObj = Object.entries<any>(obj).reduce((agg, [k, v]) => {
    if (k !== 'value') {
      agg[k] = v;
    }
    return agg;
  }, {});

  if (Array.isArray(obj.value)) {
    newObj['value'] = obj.value.map((entry, idx) => {
      const keyPath = path ? `${path}.${idx}` : String(idx);

      if (!includePath(keyPath, includePaths, excludePaths)) {
        return null;
      }

      return filterEntry(entry, includePaths, excludePaths, keyPath);
    });
  } else {
    newObj['value'] = {};

    for (const key in obj.value) {
      const keyPath = path ? `${path}.${key}` : key;

      if (includePath(keyPath, includePaths, excludePaths)) {
        if (!obj.value[key].value) {
          newObj[key] = obj.value[key];
          // console.log(key, 'bad', obj.value[key]);
          continue;
        }

        if (obj.value[key].type === 'translation') {
          newObj['value'][key] = {};
          newObj['value'][key].type = obj.value[key].type;
          newObj['value'][key].value = Object.entries<any>(
            obj.value[key].value
          ).reduce((agg, [lang, entry]) => {
            agg[lang] = filterEntry(
              entry,
              includePaths,
              excludePaths,
              `${keyPath}`
            );
            return agg;
          }, {});
        } else {
          // console.log('set', key, 'from', obj.value[key]);
          newObj['value'][key] = filterEntry(
            obj.value[key],
            includePaths,
            excludePaths,
            keyPath
          );
        }
      } else {
        // console.log('skipped', keyPath);
      }
    }
  }

  return newObj;
};

export const deleteUndefined = (
  obj: Record<string, any> | undefined
): Record<string, any> => {
  // return JSON.parse(JSON.stringify(obj));
  if (obj) {
    Object.keys(obj).forEach((key: string) => {
      if (obj[key] && typeof obj[key] === 'object') {
        deleteUndefined(obj[key]);
      } else if (typeof obj[key] === 'undefined') {
        delete obj[key]; // eslint-disable-line no-param-reassign
      }
    });
  }

  return obj;
};
