import { Theme, ThemeContext } from '@emotion/react';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { themeGet } from '@styled-system/theme-get';
import { useRouter } from 'next/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'shared/button';
import DropdownSelect from 'shared/dropdown-select';
import { Box, Container, Flex, Grid } from 'shared/grid';
import useComponentSize from 'shared/hooks/component-size';
import { openSignUpModal } from 'shared/redux/globals/actions';
import { Heading } from 'shared/typography';
import { getInEntry, unType } from 'shared/utils/cms';
import DivToBottomRight from '../../public/images/div-to-bottom-right.svg';
import { useAppDispatch } from '../../store';
import { ImageEmbed, Panel, VideoEmbed } from '../landing-shared';

const EMPTY_ARRAY = [];
const TICK_TIME = 2000;

function toFragments(text) {
  if (!text) {
    return null;
  }

  const fragments = [];

  let startIdx = 0;

  for (let idx = 0; idx <= text.length; idx++) {
    if (
      text[idx] === undefined ||
      text[idx] === '$' ||
      !text[idx].match(/[a-z0-9_]/)
    ) {
      if (startIdx < idx) {
        const fragment = text.slice(startIdx, idx);
        fragments.push(fragment);
      }
      startIdx = idx;
    }
  }

  return fragments;
}

const DEFAULT_WORD = 'your business';

const BusinessSelector = React.memo(function BusinessSelector({
  businessPageList,
  currentSlug,
  currentSlugLabel,
  background,
  panelRef,
  isOnNewLine,
}: {
  businessPageList: any;
  currentSlug: any;
  currentSlugLabel: any;
  background: any;
  panelRef: any;
  isOnNewLine: any;
}) {
  const router = useRouter();
  const faceRef = React.useRef(null);
  const { height: faceHeight } = useComponentSize(faceRef);
  const [currentFaceIdx, setCurrentFaceIdx] = React.useState(0);
  const [currentListOffset, setCurrentListOffset] = React.useState(0);
  const notListed = !currentSlugLabel;

  // Ensure face labels are only set when js runs for SEO
  // const [faceLabels, setFaceLabels] = React.useState([]);

  // React.useEffect(() => {
  //   setFaceLabels(
  //     businessPageList
  //       .filter((v) => !currentSlug || notListed || v.value === currentSlug)
  //       .map((v) => v.label)
  //   );
  // }, [businessPageList, currentSlug, notListed]);

  const faceLabels = React.useMemo(
    () =>
      businessPageList
        .filter((v) => v.label !== DEFAULT_WORD)
        .filter((v) => !currentSlug || notListed || v.value === currentSlug)
        .map((v) => v.label),
    [businessPageList, currentSlug, notListed]
  );

  const longestWord = React.useMemo(() => {
    const words = faceLabels.map((word) => word.toLowerCase());
    return words.reduce((longest, word) => {
      return word.length > longest.length ? word : longest;
    }, DEFAULT_WORD);
  }, [faceLabels]);

  React.useEffect(() => {
    if (
      faceLabels.length == 1 ||
      /bot|crawl|spider/i.test(navigator?.userAgent || '')
    ) {
      setCurrentFaceIdx(0);
      return;
    }

    const change = () => {
      setCurrentFaceIdx((v) => v + 1);
      setTimeout(() => {
        setCurrentListOffset((v) => v + 1);
      }, TICK_TIME / 2);
    };
    const id = setInterval(change, TICK_TIME);
    return () => clearInterval(id);
  }, [faceLabels.length, currentSlugLabel]);

  return (
    <DropdownSelect
      usePortal={true}
      items={businessPageList}
      itemToString={(v) => v}
      onChange={(v) => {
        if (v || (currentSlug && !v)) {
          router.push(`/${v || ''}`);
        } else {
          setTimeout(() => {
            const height = panelRef.current?.offsetHeight || 0;
            window.scrollTo({
              top: height,
              behavior: 'smooth',
            });
          });
        }
      }}
      value={currentSlugLabel ? currentSlug : DEFAULT_WORD}
      fontSize={[3, 4, 4]}
      renderToggle={(props, { isOpen, displayString }) => {
        const faceIdx = isOpen
          ? currentFaceIdx - (currentFaceIdx % 4)
          : currentFaceIdx;

        const startIdx = Math.floor(currentListOffset / 4) * 3;
        const face1 = displayString;
        const face2 = faceLabels[startIdx % faceLabels.length];
        const face3 = faceLabels[(startIdx + 1) % faceLabels.length];
        const face4 = faceLabels[(startIdx + 2) % faceLabels.length];
        const currentFaceLabels = [face1, face2, face3, face4];

        return (
          <Flex
            {...props}
            as="button"
            display="inline-block"
            fontFamily="inherit"
            fontWeight="inherit"
            textShadow="inherit"
            mt={isOnNewLine ? 0 : '0.4em'}
            style={{
              perspective: '1000px',
            }}
          >
            <Box
              fontFamily="inherit"
              fontWeight="inherit"
              justifyContent="center"
              position="relative"
              style={{
                transition: 'transform 0.2s ease-in-out',
                transformStyle: 'preserve-3d',
                transform: `translateZ(-${faceHeight / 2}px) rotateX(${
                  90 * -faceIdx
                }deg)`,
                willChange: 'transform',
              }}
              sx={{
                '&:hover': {
                  transform: `translateZ(-${faceHeight / 2}px) rotateX(${
                    90 * -(currentFaceIdx - (currentFaceIdx % 4))
                  }deg)!important`,
                  color:
                    background === 'dark'
                      ? 'rgba(255, 255, 255, 0.8)'
                      : 'rgba(0, 0, 0, 0.8)',
                },
              }}
            >
              {Array(4)
                .fill(0)
                .map((_, v) => (
                  <Box
                    ref={v === currentFaceIdx % 4 ? faceRef : null}
                    key={v}
                    width="100%"
                    aria-hidden={v !== currentFaceIdx % 4}
                    style={{
                      transform: `rotateX(${90 * v}deg) translateZ(${
                        faceHeight / 2
                      }px)`,
                      transformStyle: 'preserve-3d',
                      transition: 'filter 0.2s ease-in-out',
                      willChange: 'transform',
                    }}
                    borderRadius={2}
                    px={isOnNewLine ? 0 : '0.5em'}
                    py={isOnNewLine ? 0 : '0.15em'}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      backfaceVisibility: 'hidden',
                      backgroundColor: isOnNewLine
                        ? null
                        : 'rgba(255, 255, 255, 0.3)',
                      // color: isOnNewLine ? null : 'rgba(255, 255, 255, 0.5)',
                      '&:hover': {
                        color:
                          background === 'dark'
                            ? 'rgba(255, 255, 255, 0.8)'
                            : 'rgba(0, 0, 0, 0.8)',
                      },
                    }}
                  >
                    <Box
                      borderRadius={2}
                      sx={{
                        cursor: 'pointer',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {currentFaceLabels[Math.floor(v % 4)]}{' '}
                      <FontAwesomeIcon icon={faChevronDown} />
                    </Box>
                  </Box>
                ))}
              <Box
                opacity={0}
                px={isOnNewLine ? 0 : '0.5em'}
                py={isOnNewLine ? 0 : '0.15em'}
                aria-hidden="true"
              >
                {longestWord} <FontAwesomeIcon icon={faChevronDown} />
              </Box>
            </Box>
          </Flex>
        );
      }}
    />
  );
});

export const HeroPanel = ({ data, settingsData, decoration, ...rest }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { locale: language, query } = router;
  const currentSlug = Array.isArray(query.slug) ? query.slug[0] : query.slug;
  const background = rest.background;
  const nextBackground = rest.nextBackground;

  const {
    mediaType,
    heroImage,
    videoEmbed,
    backgroundImage,
    title,
    subtitle,
    ctaButtonText,
    sectionId,
  } = React.useMemo(() => unType(data), [data]);

  const businessPageList = React.useMemo(
    () =>
      (currentSlug === ''
        ? []
        : [
            {
              value: '',
              label: DEFAULT_WORD,
            },
          ]
      ).concat(
        (
          getInEntry('translations.businessToPage', settingsData, language) ||
          EMPTY_ARRAY
        ).map((v) => {
          return {
            value: getInEntry('page.slug', v),
            label: getInEntry('typeName', v),
          };
        })
      ),
    [currentSlug, language, settingsData]
  );

  const slugToLabel = React.useMemo(() => {
    const slugToLabel = {};
    businessPageList.forEach((v) => {
      slugToLabel[v.value] = v.label;
    });
    return slugToLabel;
  }, [businessPageList]);

  const currentSlugLabel = slugToLabel[currentSlug];

  const theme = React.useContext(ThemeContext) as Theme;
  const panelRef = React.useRef(null);

  const bottomFill = themeGet(
    `colors.${
      {
        dark: 'brand0.9',
        gray: 'gray.0',
        light: 'white',
      }[nextBackground]
    }`,
    'white'
  )({ theme });

  const titleFragments = React.useMemo(() => toFragments(title), [title]);
  const subtitleFragments = React.useMemo(
    () => toFragments(subtitle),
    [subtitle]
  );

  return (
    <Panel
      {...rest}
      sectionId={sectionId}
      ref={panelRef}
      pb="64px"
      pt={[4, null, 4]}
      sx={{
        ...{
          dark: {
            // color: 'white',
            backgroundColor: 'brand0.9',
            background: `
              linear-gradient(
                46deg
                , ${theme.colors.brand0[9]} 33%, ${theme.colors.brand0[8]} 66%, ${theme.colors.brand0[7]}) 0% 0% / cover
              `,
          },
          gray: {
            // color: 'text',
            backgroundColor: 'gray.0',
            background: `
              linear-gradient(
                46deg
                , ${theme.colors.gray[0]} 33%, ${theme.colors.gray[1]} 66%, ${theme.colors.gray[2]}) 0% 0% / cover
              `,
          },
          light: {
            // color: 'text',
            backgroundColor: 'white',
          },
        }[background],
        backgroundSize: 'cover',
      }}
      backgroundImage={backgroundImage}
    >
      <Grid gridGap={4}>
        <Container px={3}>
          <Grid gridGap={3}>
            {titleFragments && (
              <Heading
                textAlign="center"
                as="h1"
                fontSize={[4, 6, 7]}
                mx="auto"
                // lineHeight={1.2}
                fontWeight="heading"
                maxWidth={['500px', '700px']}
              >
                {titleFragments.map((v, idx, arr) => {
                  if (v[0] === '\n') {
                    return (
                      <React.Fragment key={idx}>
                        <br />
                        {v.substring(1)}
                      </React.Fragment>
                    );
                  } else if (v === '$businesses') {
                    const prev = arr[idx - 1];

                    return (
                      <BusinessSelector
                        key={idx}
                        businessPageList={businessPageList}
                        currentSlug={currentSlug}
                        currentSlugLabel={currentSlugLabel}
                        background={background}
                        panelRef={panelRef}
                        isOnNewLine={prev === '\n'}
                      />
                    );
                  }

                  return v;
                })}
              </Heading>
            )}

            {subtitleFragments && (
              <Heading
                textAlign="center"
                as="h2"
                fontSize={[3, 4, 5]}
                mx="auto"
                // lineHeight={1.7}
                fontWeight="body"
                maxWidth={['500px', '1000px']}
              >
                {subtitleFragments.map((v, idx, arr) => {
                  if (v[0] === '\n') {
                    return (
                      <React.Fragment key={idx}>
                        <br />
                        {v.substring(1)}
                      </React.Fragment>
                    );
                  } else if (v === '$businesses') {
                    const prev = arr[idx - 1];

                    return (
                      <BusinessSelector
                        key={idx}
                        businessPageList={businessPageList}
                        currentSlug={currentSlug}
                        currentSlugLabel={currentSlugLabel}
                        background={background}
                        panelRef={panelRef}
                        isOnNewLine={prev === '\n'}
                      />
                    );
                  }

                  return v;
                })}
              </Heading>
            )}
          </Grid>
        </Container>

        <Container px={3}>
          <Flex justifyContent="center">
            <Box>
              <Button
                size="large"
                color="brand1"
                // colorScheme="brand1"
                className="try-it"
                onClick={() => dispatch(openSignUpModal())}
                iconRight={faArrowRight}
              >
                {ctaButtonText || (
                  <FormattedMessage
                    id="LandingPage.tryIt"
                    defaultMessage="Sign up"
                  />
                )}
              </Button>
            </Box>
          </Flex>
        </Container>

        <Box position="relative" mt="-64px" sx={{ pointerEvents: 'none' }}>
          {decoration !== 'none' ? (
            <Box
              as={DivToBottomRight}
              sx={{
                fill: bottomFill,
                display: 'block',
                position: 'absolute',
                bottom: '-1px',
                transform: {
                  slantUp: 'scaleX(-1)',
                  slantDown: '',
                  taperLeft: '',
                  taperRight: 'scaleX(-1)',
                }[decoration],
              }}
            />
          ) : (
            <Box
              sx={{
                position: 'absolute',
                bottom: '-1px',
              }}
              width="100%"
              paddingBottom="10%"
              bg={bottomFill}
            />
          )}
          <Box
            bg={bottomFill}
            width="100%"
            height="64px"
            bottom="-64px"
            position="absolute"
          ></Box>
          <Container px={3} zIndex={1} position="relative" top="64px">
            <Box mx="auto" zIndex={2} px={3} sx={{ pointerEvents: 'all' }}>
              {mediaType === 'image' && (
                <ImageEmbed priority value={heroImage} />
              )}
              {mediaType === 'videoEmbed' && (
                <VideoEmbed priority value={videoEmbed} />
              )}
            </Box>
          </Container>
        </Box>
      </Grid>
    </Panel>
  );
};

export default HeroPanel;
