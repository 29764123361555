import React from 'react';
import { Box, BoxProps } from '../grid';

const arrowCss = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  borderStyle: 'solid',
  borderWidth: '0 6px 6px 0',
  display: 'inline-block',
  padding: '6px',
  cursor: 'pointer',
};

type Props = BoxProps;

export function Arrow({
  ref,
  color,
  ...props
}: Props & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  return (
    <Box
      ref={ref}
      __css={{
        ...arrowCss,
        borderColor: color,
      }}
      {...props}
    />
  );
}

export const Arrows = Box;

export function ArrowPrev({
  ref,
  color,
  ...props
}: Props & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  return (
    <Box
      ref={ref}
      __css={{
        ...arrowCss,
        left: '12px',
        transform: 'rotate(135deg)',
        borderColor: color,
      }}
      {...props}
    />
  );
}

export function ArrowNext({
  ref,
  color,
  ...props
}: Props & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  return (
    <Box
      ref={ref}
      __css={{
        ...arrowCss,
        right: '12px',
        transform: 'rotate(-45deg)',
        borderColor: color,
      }}
      {...props}
    />
  );
}

export function Nav({
  ref,
  color,
  ...props
}: Props & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  return (
    <Box
      ref={ref}
      __css={{
        position: 'absolute',
        bottom: '12px',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...props}
    />
  );
}

interface NavItemProps {
  color: string;
  active: boolean;
}

export function NavItem({
  ref,
  color,
  active,
  ...props
}: NavItemProps & BoxProps) {
  return (
    <Box
      ref={ref}
      __css={{
        width: '12px',
        height: '12px',
        margin: '3px 6px',
        borderRadius: '50%',
        background: color,
        opacity: active ? 1.0 : 0.5,
        cursor: 'pointer',
      }}
      {...props}
    />
  );
}
