import { faBullseyePointer, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Autocomplete from 'shared/autocomplete';
import Button from 'shared/button';
import ColorMode from 'shared/color-modes';
import countries from 'shared/countries';
import countryToCurrency from 'shared/country-code-to-currency';
import { Box, Flex, Grid } from 'shared/grid';
import { StandardModal } from 'shared/modal';
import { openSignUpModal } from 'shared/redux/globals/actions';
import { fetchSmsPricing } from 'shared/redux/pricing/actions';
import { Heading, Link, Text } from 'shared/typography';
import { unType } from 'shared/utils/cms';
import { CORE_FEATURE_DESCRIPTIONS, FEATURES } from 'shared/utils/constants';
import { currencies } from 'shared/utils/currencies';
import { useAppDispatch, useAppSelector } from '../../store';
import {
  GridContainer,
  Panel,
  SectionHeading,
  SectionSubtitle,
  SectionTagline,
} from '../landing-shared';

import { SYSTEM_CURRENCIES } from 'shared/utils/constants';

const countryAutocompleteList = countries;
const countryCodeToName = countries.reduce((agg, cur) => {
  agg[cur.value] = cur.label;
  return agg;
}, {});
const currenciesAutocompleteList = Object.values<any>(currencies).map((v) => ({
  label: v.name,
  value: v.code,
}));

export const CoreFeatures = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Grid
      as="button"
      type="button"
      gridGap={1}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      {!isOpen ? (
        <Text fontSize={2}>
          <Text display="inline" color="brand0.4" mr={3}>
            <FontAwesomeIcon icon={faPlus} />
          </Text>
          <Box display="inline-block">Core features</Box>
        </Text>
      ) : (
        CORE_FEATURE_DESCRIPTIONS.map((feature, idx) => (
          <Text key={idx} fontSize={2}>
            <Text display="inline" color="brand0.4" mr={3}>
              <FontAwesomeIcon icon={faBullseyePointer} />
            </Text>
            <Box display="inline-block">{feature}</Box>
          </Text>
        ))
      )}
    </Grid>
  );
};

const Pricing = ({ data, ...rest }) => {
  const { title, subtitle, tagline, sectionId } = React.useMemo(
    () => unType(data),
    [data]
  );

  const planData = useAppSelector((state) => state.plans.data);

  const userLocale =
    typeof window === 'undefined'
      ? globalThis['LOCALE'] || 'en-GB'
      : navigator.language;

  const language = userLocale.substr(0, 2);

  const dispatch = useAppDispatch();
  const smsPricing = useAppSelector((state) => state.pricing.smsPricing || {});

  const exchangeRates = useAppSelector(
    (state) => state.pricing.exchangeRates || {}
  );
  const countryCode = useAppSelector(
    (state) => state.geoip.country_code || 'ZA'
  );

  const [isSMSModalOpen, setIsSMSModalOpen] = React.useState(false);
  const [destination, setDestination] = React.useState(countryCode);

  const [currency, setCurrency] = React.useState(
    SYSTEM_CURRENCIES.includes(countryToCurrency[countryCode])
      ? countryToCurrency[countryCode]
      : 'USD'
  );

  const plans = React.useMemo(
    () =>
      Object.values<any>(planData)
        .filter((v) => !v.is_hidden)
        .map((v) => ({
          ...v,
          name: v.name[language] || v.name.en,
          // price: v.prices.find((p) => p.currency === currency),
          price: v.prices.reduce((agg, cur) => {
            agg[cur.currency] = {
              ...cur,
              base_price: cur.base_price * (countryCode == 'ZA' ? 1.15 : 1),
              additional_resource_price:
                cur.additional_resource_price *
                (countryCode == 'ZA' ? 1.15 : 1),
            };

            return agg;
          }, {}),
        }))
        .filter((v) => v.price) // Blank if billing currency is not loeaded
        .sort((a, b) =>
          Number(a.price['ZAR'].base_price) > Number(b.price['ZAR'].base_price)
            ? 1
            : -1
        ),
    [countryCode, language, planData]
  );

  React.useEffect(() => {
    setDestination(countryCode);
    setCurrency(
      SYSTEM_CURRENCIES.includes(countryToCurrency[countryCode])
        ? countryToCurrency[countryCode]
        : 'USD'
    );
  }, [countryCode]);

  React.useEffect(() => {
    // dispatch(fetchIPLocation());
    dispatch(fetchSmsPricing());
  }, [dispatch]);

  const zarPerToken = 0.8 * (countryCode == 'ZA' ? 1.15 : 1);
  const tokensPerSMS = smsPricing[destination] || 1;
  const exchangeRate = exchangeRates[currency] || 1;
  const costPerSMS = tokensPerSMS * zarPerToken * exchangeRate;

  // const { height, width } = useComponentSize(ref);

  const sortedFeatures = React.useMemo(() => {
    const featureCounts: Record<string, number> = {};
    for (const plan of plans) {
      for (const feature of plan.features) {
        featureCounts[feature] = (featureCounts[feature] || 0) + 1;
      }
    }

    return Object.entries(featureCounts)
      .sort(([k1, v1], [k2, v2]) => FEATURES[k1].localeCompare(FEATURES[k2]))
      .sort(([k1, v1], [k2, v2]) => v2 - v1)
      .map(([k, v]) => k);
  }, [plans]);

  const pricingItems = React.useMemo(() => {
    return plans.map((plan) => {
      const features = sortedFeatures
        .filter((k) => plan.features.includes(k))
        .map((k) => FEATURES[k]);

      return {
        ...plan,
        baseResourceCount: {
          USD: plan.price['USD'].base_resource_count,
          ZAR: plan.price['ZAR'].base_resource_count,
        },
        monthlyPrice: {
          USD: plan.price['USD'].base_price,
          ZAR: plan.price['ZAR'].base_price,
        },
        monthlyPriceAdditionalUser: {
          USD: plan.price['USD'].additional_resource_price,
          ZAR: plan.price['ZAR'].additional_resource_price,
        },
        features,
      };
    });
  }, [plans, sortedFeatures]);

  return (
    <Panel {...rest} sectionId={sectionId}>
      <GridContainer py={4}>
        <Grid>
          {tagline && <SectionTagline>{tagline}</SectionTagline>}
          <SectionHeading>{title}</SectionHeading>
          {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
        </Grid>

        <Grid
          gridTemplateColumns={
            pricingItems.length > 3
              ? ['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr']
              : 'repeat( auto-fit, minmax(250px, 1fr) )'
          }
        >
          {pricingItems.map(
            (
              {
                name,
                features,
                monthlyPrice,
                monthlyPriceAdditionalUser,
                baseResourceCount,
              },
              idx
            ) => {
              return (
                <React.Fragment key={idx}>
                  <Grid
                    height="100%"
                    gridGap={[3, 4]}
                    px={[3, 3]}
                    py={[4, 4]}
                    gridTemplateRows={['auto auto auto auto 1fr']}
                  >
                    <Heading fontSize={[4, 6]}>{name}</Heading>

                    <Box mx={-3} px={3} py={3} bg="brand0.0">
                      <Heading fontSize={[3, 5]}>
                        <FormattedNumber
                          value={
                            monthlyPrice[currency] ||
                            monthlyPrice['ZAR'] * exchangeRate
                          }
                          style="currency"
                          currency={currency}
                          minimumFractionDigits={0}
                          maximumFractionDigits={2}
                        />

                        <Box fontSize={[2, 2]} display="inline-block" ml={1}>
                          / month{' '}
                          {!monthlyPrice[currency] &&
                            Number(monthlyPrice['ZAR']) > 0 &&
                            '*'}
                        </Box>
                      </Heading>
                    </Box>

                    <Grid>
                      <Grid gridGap={2}>
                        <Flex
                          alignItems="baseline"
                          sx={{ gap: 2 }}
                          fontSize={2}
                        >
                          <Box pt={1}>
                            <FontAwesomeIcon icon={faBullseyePointer} />
                          </Box>
                          {(baseResourceCount[currency] ||
                            baseResourceCount['ZAR']) != 1 ? (
                            <Text display="inline-block">
                              Up to{' '}
                              {baseResourceCount[currency] ||
                                baseResourceCount['ZAR']}{' '}
                              users/calendars (reception is free)
                            </Text>
                          ) : (
                            <Text display="inline-block">
                              One user/calendar (reception is free)
                            </Text>
                          )}
                        </Flex>

                        {(monthlyPriceAdditionalUser[currency] ||
                          monthlyPriceAdditionalUser['ZAR']) && (
                          <Flex
                            alignItems="baseline"
                            sx={{ gap: 2 }}
                            fontSize={2}
                          >
                            <Box pt={1}>
                              <FontAwesomeIcon icon={faBullseyePointer} />
                            </Box>
                            <Text display="inline-block">
                              <FormattedNumber
                                value={
                                  monthlyPriceAdditionalUser[currency] ||
                                  monthlyPriceAdditionalUser['ZAR'] *
                                    exchangeRate
                                }
                                style="currency"
                                currency={currency}
                                minimumFractionDigits={0}
                                maximumFractionDigits={2}
                              />{' '}
                              per additional user{' '}
                              {!monthlyPrice[currency] &&
                                Number(monthlyPrice['ZAR']) > 0 &&
                                '*'}
                            </Text>
                          </Flex>
                        )}

                        <Flex
                          alignItems="baseline"
                          sx={{ gap: 2 }}
                          fontSize={2}
                        >
                          <Box pt={1}>
                            <FontAwesomeIcon icon={faBullseyePointer} />
                          </Box>
                          <Text display="inline-block">
                            <Link
                              as="button"
                              onClick={() => setIsSMSModalOpen(true)}
                            >
                              <FormattedMessage
                                id="LandingPage.smsCostDescription"
                                defaultMessage="{price} per SMS to {country}"
                                values={{
                                  price: (
                                    <FormattedNumber
                                      value={costPerSMS}
                                      style="currency"
                                      currency={currency}
                                      minimumFractionDigits={0}
                                      maximumFractionDigits={2}
                                    />
                                  ), //currencyFormat(costPerSMS, currency, 0),
                                  country: countryCodeToName[destination],
                                }}
                              />
                            </Link>
                          </Text>
                        </Flex>
                      </Grid>
                    </Grid>

                    <Box>
                      <Button
                        width="100%"
                        size="large"
                        color="brand1"
                        onClick={() => dispatch(openSignUpModal())}
                      >
                        <FormattedMessage
                          id="LandingPage.tryIt"
                          defaultMessage="Sign up"
                        />
                      </Button>
                    </Box>

                    <Grid gridGap={2} alignContent="start">
                      {features.length ? (
                        <Heading fontSize={2}>Features</Heading>
                      ) : (
                        <>
                          <Heading fontSize={2}>Core features</Heading>
                          <Grid gridGap={1}>
                            {CORE_FEATURE_DESCRIPTIONS.map((feature, idx) => (
                              <Flex key={idx} fontSize={2}>
                                <Text display="inline" color="brand0.4" mr={3}>
                                  <FontAwesomeIcon icon={faBullseyePointer} />
                                </Text>
                                <Box display="inline-block">{feature}</Box>
                              </Flex>
                            ))}
                          </Grid>
                        </>
                      )}
                      {features.length > 0 && (
                        <Grid gridGap={1}>
                          <CoreFeatures />
                          {features.map((feature, idx) => (
                            <Flex key={idx} fontSize={2}>
                              <Text display="inline" color="brand1.4" mr={3}>
                                <FontAwesomeIcon icon={faBullseyePointer} />
                              </Text>
                              <Box display="inline-block">{feature}</Box>
                            </Flex>
                          ))}
                        </Grid>
                      )}
                      {/* {features.map(({ name, title, page }, idx) =>
                        title ? (
                          <Heading fontSize={2} key={idx} fontWeight="bold">
                            {title}
                          </Heading>
                        ) : page ? (
                          <Link
                            as={NextLink}
                            href={`/${page.slug === 'home' ? '' : page.slug}`}
                            display="block"
                            key={idx}
                            fontSize={2}
                          >
                            <FontAwesomeIcon icon={faCheckCircle} />{' '}
                            <Box display="inline-block" ml={1}>
                              {name}
                            </Box>
                          </Link>
                        ) : (
                          <Box key={idx} fontSize={2}>
                            <FontAwesomeIcon icon={faCheckCircle} />{' '}
                            <Box display="inline-block" ml={1}>
                              {name}
                            </Box>
                          </Box>
                        )
                      )} */}
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            }
          )}
        </Grid>

        <Flex justifyContent="center">
          {!SYSTEM_CURRENCIES.includes(currency) && (
            <Box fontSize={1}>
              *
              <Link
                href={`http://finance.yahoo.com/currency-converter/#from=ZAR;to=${currency};amt=1`}
                rel="noopener noreferrer"
                target="_blank"
              >
                Estimate based on current exchange rate.
              </Link>
            </Box>
          )}
        </Flex>

        <ColorMode mode="light">
          <StandardModal
            isOpen={isSMSModalOpen}
            close={() => setIsSMSModalOpen(false)}
            title={
              <FormattedMessage
                id="LandingPage.smsCostCalculator"
                defaultMessage="SMS cost calculator"
              />
            }
          >
            {() => (
              <>
                <Grid
                  gridTemplateColumns={['1fr', '1fr 1fr']}
                  className="pricing"
                  color="text"
                >
                  <Grid className="options">
                    <Box>
                      <Autocomplete
                        name="currency"
                        label={
                          <FormattedMessage
                            id="LandingPage.currency"
                            defaultMessage="Currency"
                          />
                        }
                        display="block"
                        onChange={setCurrency}
                        value={currency || null}
                        itemToString={(v) => currencies[v]?.name}
                        items={currenciesAutocompleteList}
                      />
                    </Box>
                    <Box>
                      <Autocomplete
                        name="destination"
                        label={
                          <FormattedMessage
                            id="LandingPage.smsDestination"
                            defaultMessage="SMS Destination"
                          />
                        }
                        onChange={(v) => setDestination(v)}
                        value={destination || null}
                        itemToString={(v) => countryCodeToName[v]}
                        items={countryAutocompleteList}
                      />
                    </Box>
                  </Grid>
                  <Box className="price">
                    <Flex
                      borderRadius={2}
                      textAlign="center"
                      flexDirection="column"
                      justifyContent="center"
                      p={3}
                      bg="brand0.0"
                      flex="1 1 0"
                      height="100%"
                    >
                      <Box fontSize={6} fontWeight="heading" color="primary">
                        <FormattedNumber
                          value={costPerSMS}
                          style="currency"
                          currency={currency}
                          minimumFractionDigits={0}
                          maximumFractionDigits={2}
                        />

                        {currency !== 'ZAR' && <span>*</span>}
                      </Box>
                      <Box fontWeight="heading">
                        <FormattedMessage
                          id="LandingPage.perSmsSuffix"
                          defaultMessage="per SMS notification"
                        />
                      </Box>
                    </Flex>
                  </Box>
                </Grid>

                {currency !== 'ZAR' && (
                  <Box mt={2}>
                    <p>
                      *
                      <Link
                        href={`http://finance.yahoo.com/currency-converter/#from=ZAR;to=${currency};amt=1`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <FormattedMessage
                          id="LandingPage.pricingAsterisk"
                          defaultMessage={`Estimate based on current {currencyName1} to {currencyName2} exchange rate.`}
                          values={{
                            currencyName1: currencies['ZAR']?.name || '',
                            currencyName2: currencies[currency]?.name || '',
                          }}
                        />
                      </Link>
                    </p>
                  </Box>
                )}
              </>
            )}
          </StandardModal>
        </ColorMode>
      </GridContainer>
    </Panel>
  );
};

export default Pricing;
